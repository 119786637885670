import * as React from 'react';
import styled from 'styled-components';

import { Body, HeaderH5 } from './Typography';

const DevelopLayout = styled.div`
	& > :not(:last-child) {
		margin-bottom: 1rem;
	}
`;

interface DevelopProps {
	title: string;
	details: string;
}

export const Develop: React.FC<DevelopProps> = props => {
	const { title, details } = props;

	return (
		<DevelopLayout>
			<HeaderH5>{title}</HeaderH5>
			<Body>{details}</Body>
		</DevelopLayout>
	);
};
