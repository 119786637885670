import * as React from 'react';
import styled from 'styled-components';

import { Icons } from '@thingos/design-icons';

import lightingColors from '../styles/lighting';

const DownloadFilesStyle = styled.div({
	border: '2px solid #fff',
	borderRadius: 8,
	width: '16rem',
	backgroundColor: 'rgba(255,255,255,0)',
	transition: '0.3s',
	marginBottom: '1rem',
	color: 'white',

	'&:hover': {
		backgroundColor: 'rgba(255,255,255,1)',
		color: '#171635',
		transition: '0.3s',
		boxShadow: 'rgba(255, 255, 255, 0.5) 0px 0px 10px',
	},

	'&:active': {
		backgroundColor: 'rgba(200,200,200,1)',
		border: '2px solid rgba(200,200,200,1)',
		color: '#171635',
		transition: '0.3s',
		boxShadow: 'rgba(255, 255, 255, 0) 0px 0px 10px',
	},
});

const DownloadFilesLayout = styled.div({
	display: 'flex',
	flexDirection: 'row',
	margin: '0.5rem 1rem 0.5rem 1rem',
	alignItems: 'center',
});

interface DownloadsFileProps {
	icon: any;
	fileName: string;
	file: string;
}

export const DownloadsFile: React.FC<DownloadsFileProps> = props => {
	const { icon, fileName, file } = props;
	return (
		<a href={file} download={fileName} style={{ textDecoration: 'none' }}>
			<DownloadFilesStyle>
				<DownloadFilesLayout>
					<Icons kind={icon} color={lightingColors.highlight.orange} />
					<div style={{ marginLeft: '0.5rem' }}>{fileName}</div>
				</DownloadFilesLayout>
			</DownloadFilesStyle>
		</a>
	);
};
