import * as React from 'react';
import styled from 'styled-components';

import { HeaderH4 } from './Typography';

const DownloadsLayout = styled.div({
	':not(:last-child)': { marginRight: '4rem' },
	'>:not(:last-child)': { marginBottom: '1rem' },
});

interface DownloadsProps {
	title: string;
}

export const Downloads: React.FC<DownloadsProps> = props => {
	const { title, children } = props;

	return (
		<DownloadsLayout>
			<HeaderH4 style={{ marginBottom: '1.5rem' }}>{title}</HeaderH4>
			{children}
		</DownloadsLayout>
	);
};
