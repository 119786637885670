import * as React from 'react';
import styled from 'styled-components';

import { Body, HeaderH3 } from './Typography';

const ContainerRoot = styled.div`
	margin-top: 5.5rem;
	margin-bottom: 6rem;
`;

const ContainerLayout = styled.div`
	margin-top: 2rem;
	display: flex;
	flex-direction: row;
	flex: 1;
	@media screen and (max-width: 600px) {
		flex-wrap: wrap;
	}
`;

interface DownloadsContainerProps {
	title: string;
	description: string;
}

export const DownloadsContainer: React.FC<DownloadsContainerProps> = props => {
	const { title, description, children } = props;

	return (
		<ContainerRoot>
			<HeaderH3 style={{ marginBottom: '1rem' }}>{title}</HeaderH3>
			<Body>{description}</Body>
			<ContainerLayout>{children}</ContainerLayout>
		</ContainerRoot>
	);
};
