import * as React from 'react';
import styled from 'styled-components';

import { Appstore } from './Appstore';
import { Body, HeaderH3 } from './Typography';

const AppDetailsContainer = styled.div({
	marginTop: '6rem',
	marginBottom: '6rem',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'space-between',
	'@media screen and (max-width: 600px)': {
		flexWrap: 'wrap',
		justifyContent: 'center',
	},
});

const AppDetailsText = styled.div`
	display: flex;
	flex-direction: column;
	width: 400px;
	color: #fff;
	& > :not(:last-child) {
		margin-bottom: 1rem;
	}
	@media screen and (max-width: 600px) {
		flex-wrap: wrap;
	}
`;

const StyledImage = styled.div({
	display: 'flex',
	width: 300,
	alignSelf: 'flex-end',
	'@media screen and (max-width: 600px)': {
		marginTop: '4rem',
	},
});

const StyledDetails = styled.div({
	marginTop: '2rem',
	marginBottom: '3rem !important',
});

interface AppDetailsDeveloperProps {
	title: string;
	description: string;
	details: React.ReactElement;
}

export const AppDetailsDeveloper: React.FC<AppDetailsDeveloperProps> = props => {
	const { title, description, details, children } = props;
	return (
		<AppDetailsContainer>
			<AppDetailsText>
				<HeaderH3>{title}</HeaderH3>
				<Body>{description}</Body>
				<StyledDetails>{details}</StyledDetails>
				<Appstore />
			</AppDetailsText>
			<StyledImage>{children}</StyledImage>
		</AppDetailsContainer>
	);
};
