/**
 * Mesh developers page
 *
 * @author Anja Bisako
 * @version 0.0.1
 */
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import styled from 'styled-components';

import { AppDetailsDeveloper } from '../components/AppDetailsDeveloper';
import { Contact } from '../components/Contact';
import { Container } from '../components/Container';
import { Develop } from '../components/Develop';
import { DevelopContainer } from '../components/DevelopContainer';
import { Downloads } from '../components/Downloads';
import { DownloadsContainer } from '../components/DownloadsContainer';
import { DownloadsFile } from '../components/DownloadsFile';
import { MeshlightingFooter } from '../components/MeshlightingFooter';
import { Page } from '../components/Page';
import { HeaderH2, Subtitle, Title } from '../components/Typography';
import { VideoContainer } from '../components/VideoContainer';
import { IndexLayout } from '../layouts';
import lightingColors from '../styles/lighting';

import BulletPoint from '../svgs/bullet-point.svg';

const StyledLi = styled.div({
	display: 'flex',
	flexDirection: 'row',
	':not(:first-child)': {
		marginTop: '1rem',
	},
});

const StyledUl = styled.div``;

const StyledListText = styled.div({
	marginLeft: '1rem',
});

const StyledImg = styled.div({
	width: '100%',
	flex: 1,
	display: 'flex',
	alignSelf: 'center',
});

const IndexPage: React.FC = () => {
	const { t } = useTranslation('developers');

	return (
		<IndexLayout>
			<Page>
				<Container>
					<Title>
						<HeaderH2>
							{t('title')}
							<HeaderH2 style={{ display: 'inline', color: lightingColors.highlight.orange }}>
								.
							</HeaderH2>
						</HeaderH2>
						<Subtitle>{t('slogan.subtitle')}</Subtitle>
					</Title>
					<DevelopContainer>
						<Develop title={t('develop.faster.title')} details={t('develop.faster.description')} />
						<Develop
							title={t('develop.smarter.title')}
							details={t('develop.smarter.description')}
						/>
						<Develop
							title={t('develop.cheaper.title')}
							details={t('develop.cheaper.description')}
						/>
					</DevelopContainer>
				</Container>

				<Container>
					<AppDetailsDeveloper
						title={t('product.app.meshthings')}
						description={t('product.app.meshthings.description')}
						details={
							<StyledUl>
								<StyledLi>
									<img src={BulletPoint} alt="bullet point" />
									<StyledListText>{t('product.app.meshthings.detail1')}</StyledListText>
								</StyledLi>
								<StyledLi>
									<img src={BulletPoint} alt="bullet point" />
									<StyledListText>{t('product.app.meshthings.detail2')}</StyledListText>
								</StyledLi>
								<StyledLi>
									<img src={BulletPoint} alt="bullet point" />
									<StyledListText>{t('product.app.meshthings.detail3')}</StyledListText>
								</StyledLi>
								<StyledLi>
									<img src={BulletPoint} alt="bullet point" />
									<StyledListText>{t('product.app.meshthings.detail4')}</StyledListText>
								</StyledLi>
							</StyledUl>
						}
					>
						<StyledImg>
							<StaticImage
								src="../content/meshapp-front.png"
								style={{ flex: 1, display: 'flex' }}
								alt="Meshthings app"
							/>
						</StyledImg>
					</AppDetailsDeveloper>
				</Container>
			</Page>

			<Page sectionBackground="darkBackground">
				<Container>
					<VideoContainer
						header={t('video.header')}
						source="https://www.youtube.com/embed/RCfGNRV3Mdk?modestbranding=1&color=white"
						// source="https://www.youtube.com/embed/JiiVwum1QMk?modestbranding=1&color=white"
						videoTitle={t('video.title')}
					/>
				</Container>
			</Page>

			<Page sectionBackground="lightBackground">
				<Container>
					<DownloadsContainer title={t('downloads.title')} description={t('downloads.description')}>
						<Downloads title={t('downloads.gettingstarted')}>
							<DownloadsFile
								icon="ui/document"
								fileName={t('downloads.gettingstarted.quickguide')}
								file={'ErsteSchritte.pdf'}
							/>
							{/*
							<DownloadsFile
								icon="ui/scene"
								fileName={t('downloads.gettingstarted.video')}
								file="2020_Market_Update-EN.pdf"
							/>*/}
						</Downloads>
						<Downloads title={t('downloads.meshthing')}>
							<DownloadsFile
								icon="ui/document"
								fileName={t('downloads.meshthing.cheatsheet')}
								file="PinBelegungv1.2.pdf"
							/>
						</Downloads>
						{/* TODO: bring back when dokument is ready */}
						{/* <Downloads title={t('downloads.developmentsuitcase')}> */}
						{/* <DownloadsFile
								icon="ui/networkDecentral"
								fileName={t('downloads.developmentsuitcase.meshnetwork')}
								file="2020_Market_Update-EN.pdf"
							/>
						 	<DownloadsFile
								icon="ui/document"
								fileName={t('downloads.developmentsuitcase.suitcaseinlay')}
								file="2020_Market_Update-EN.pdf"
							/>*/}
						{/* <DownloadsFile
								icon="ui/person"
								fileName={t('downloads.developmentsuitcase.contactsale')}
								file="MarcoKimmerle.vcf"
							/>
							<DownloadsFile
								icon="ui/person"
								fileName={t('downloads.developmentsuitcase.contacttech')}
								file="PatrickBader.vcf"
							/> */}
						{/* </Downloads> */}
					</DownloadsContainer>
				</Container>
			</Page>

			<Page sectionBackground="darkBackground" id="Contact">
				<Container>
					<Contact
						title={t('contact.title')}
						text={t('contact.description')}
						buttonText={t('contact.button')}
						url={'mailto:info@thingos.io'}
					/>
				</Container>
			</Page>

			<Page sectionBackground="darkBackground">
				<Container>
					<MeshlightingFooter />
				</Container>
			</Page>
		</IndexLayout>
	);
};

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;

export default IndexPage;
