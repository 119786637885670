import * as React from 'react';
import styled from 'styled-components';

const DevelopRoot = styled.div`
	margin-top: 5.5rem;
	margin-bottom: 6rem;
`;

const DevelopLayout = styled.div`
	margin-top: 2rem;
	display: flex;
	flex-direction: row;
	flex: 1;
	& > :not(:last-child) {
		margin-right: 1rem;
	}
	@media screen and (max-width: 600px) {
		flex-wrap: wrap;
	}
`;

export const DevelopContainer: React.FC = props => {
	const { children } = props;
	return (
		<DevelopRoot>
			<DevelopLayout>{children}</DevelopLayout>
		</DevelopRoot>
	);
};
